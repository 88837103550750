/**
 * @module constants/elements
 * @private
 * @description Exports methods for {@link Elem} for creating html-elements.
 */

/**
 * @const
 * @type {String[]}
 */
export default [
  /**
   * @member {Function} Elem#a
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'a',

  /**
   * @member {Function} Elem#abbr
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'abbr',

  /**
   * @member {Function} Elem#address
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'address',

  /**
   * @member {Function} Elem#area
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'area',

  /**
   * @member {Function} Elem#article
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'article',

  /**
   * @member {Function} Elem#audio
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'audio',

  /**
   * @member {Function} Elem#b
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'b',

  /**
   * @member {Function} Elem#base
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'base',

  /**
   * @member {Function} Elem#bdi
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'bdi',

  /**
   * @member {Function} Elem#bdo
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'bdo',

  /**
   * @member {Function} Elem#blockquote
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'blockquote',

  /**
   * @member {Function} Elem#body
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'body',

  /**
   * @member {Function} Elem#br
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'br',

  /**
   * @member {Function} Elem#button
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'button',

  /**
   * @member {Function} Elem#canvas
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'canvas',

  /**
   * @member {Function} Elem#caption
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'caption',

  /**
   * @member {Function} Elem#cite
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'cite',

  /**
   * @member {Function} Elem#code
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'code',

  /**
   * @member {Function} Elem#col
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'col',

  /**
   * @member {Function} Elem#colgroup
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'colgroup',

  /**
   * @member {Function} Elem#content
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'content',

  /**
   * @member {Function} Elem#datalist
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'datalist',

  /**
   * @member {Function} Elem#dd
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'dd',

  /**
   * @member {Function} Elem#del
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'del',

  /**
   * @member {Function} Elem#details
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'details',

  /**
   * @member {Function} Elem#dfn
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'dfn',

  /**
   * @member {Function} Elem#dialog
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'dialog',

  /**
   * @member {Function} Elem#div
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'div',

  /**
   * @member {Function} Elem#dl
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'dl',

  /**
   * @member {Function} Elem#dt
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'dt',

  /**
   * @member {Function} Elem#element
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'element',

  /**
   * @member {Function} Elem#em
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'em',

  /**
   * @member {Function} Elem#embed
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'embed',

  /**
   * @member {Function} Elem#fieldset
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'fieldset',

  /**
   * @member {Function} Elem#figcaption
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'figcaption',

  /**
   * @member {Function} Elem#figure
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'figure',

  /**
   * @member {Function} Elem#footer
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'footer',

  /**
   * @member {Function} Elem#form
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'form',

  /**
   * @member {Function} Elem#h1
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'h1',

  /**
   * @member {Function} Elem#h2
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'h2',

  /**
   * @member {Function} Elem#h3
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'h3',

  /**
   * @member {Function} Elem#h4
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'h4',

  /**
   * @member {Function} Elem#h5
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'h5',

  /**
   * @member {Function} Elem#h6
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'h6',

  /**
   * @member {Function} Elem#head
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'head',

  /**
   * @member {Function} Elem#header
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'header',

  /**
   * @member {Function} Elem#hgroup
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'hgroup',

  /**
   * @member {Function} Elem#hr
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'hr',

  /**
   * @member {Function} Elem#i
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'i',

  /**
   * @member {Function} Elem#iframe
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'iframe',

  /**
   * @member {Function} Elem#img
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'img',

  /**
   * @member {Function} Elem#input
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'input',

  /**
   * @member {Function} Elem#ins
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'ins',

  /**
   * @member {Function} Elem#kbd
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'kbd',

  /**
   * @member {Function} Elem#label
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'label',

  /**
   * @member {Function} Elem#legend
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'legend',

  /**
   * @member {Function} Elem#li
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'li',

  /**
   * @member {Function} Elem#link
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'link',

  /**
   * @member {Function} Elem#main
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'main',

  /**
   * @member {Function} Elem#mark
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'mark',

  /**
   * @member {Function} Elem#menu
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'menu',

  /**
   * @member {Function} Elem#menuitem
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'menuitem',

  /**
   * @member {Function} Elem#meta
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'meta',

  /**
   * @member {Function} Elem#meter
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'meter',

  /**
   * @member {Function} Elem#nav
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'nav',

  /**
   * @member {Function} Elem#noscript
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'noscript',

  /**
   * @member {Function} Elem#null
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'null',

  /**
   * @member {Function} Elem#ol
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'ol',

  /**
   * @member {Function} Elem#optgroup
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'optgroup',

  /**
   * @member {Function} Elem#option
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'option',

  /**
   * @member {Function} Elem#output
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'output',

  /**
   * @member {Function} Elem#p
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'p',

  /**
   * @member {Function} Elem#param
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'param',

  /**
   * @member {Function} Elem#pre
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'pre',

  /**
   * @member {Function} Elem#progress
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'progress',

  /**
   * @member {Function} Elem#q
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'q',

  /**
   * @member {Function} Elem#rp
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'rp',

  /**
   * @member {Function} Elem#rt
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'rt',

  /**
   * @member {Function} Elem#rtc
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'rtc',

  /**
   * @member {Function} Elem#ruby
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'ruby',

  /**
   * @member {Function} Elem#s
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  's',

  /**
   * @member {Function} Elem#samp
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'samp',

  /**
   * @member {Function} Elem#script
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'script',

  /**
   * @member {Function} Elem#section
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'section',

  /**
   * @member {Function} Elem#select
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'select',

  /**
   * @member {Function} Elem#shadow
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'shadow',

  /**
   * @member {Function} Elem#small
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'small',

  /**
   * @member {Function} Elem#source
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'source',

  /**
   * @member {Function} Elem#span
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'span',

  /**
   * @member {Function} Elem#strong
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'strong',

  /**
   * @member {Function} Elem#style
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'style',

  /**
   * @member {Function} Elem#sub
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'sub',

  /**
   * @member {Function} Elem#summary
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'summary',

  /**
   * @member {Function} Elem#sup
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'sup',

  /**
   * @member {Function} Elem#table
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'table',

  /**
   * @member {Function} Elem#tbody
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'tbody',

  /**
   * @member {Function} Elem#td
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'td',

  /**
   * @member {Function} Elem#template
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'template',

  /**
   * @member {Function} Elem#textarea
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'textarea',

  /**
   * @member {Function} Elem#tfoot
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'tfoot',

  /**
   * @member {Function} Elem#th
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'th',

  /**
   * @member {Function} Elem#thead
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'thead',

  /**
   * @member {Function} Elem#time
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'time',

  /**
   * @member {Function} Elem#title
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'title',

  /**
   * @member {Function} Elem#tr
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'tr',

  /**
   * @member {Function} Elem#track
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'track',

  /**
   * @member {Function} Elem#u
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'u',

  /**
   * @member {Function} Elem#ul
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'ul',

  /**
   * @member {Function} Elem#var
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'var',

  /**
   * @member {Function} Elem#video
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'video',

  /**
   * @member {Function} Elem#wbr
   * @type {Function}
   * @param {...String} appliedExpressions
   * @returns {Elem}
   */
  'wbr'
];
