/**
 * @module constants/constructors
 * @private
 * @description Exports constructors levels.
 */

/**
 * @const
 * @type {Array[]}
 */
export default [
  [],
  [],
  []
];
